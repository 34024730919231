@import "../../scss/variables.scss";

.ticketCard {
  border-radius: $border-radius-md;
  background-color: $headerBgColor;
  max-width: 370px;
  .ticketCardContainer {
    display: relative;
    margin: 10px;
    border-radius: $border-radius-md;
    background-color: $bgColor;
    .qrContainer {
      display: flex;
      flex-direction: column;
      padding: $space-lg $space-lg;
      align-items: center;
      justify-content: center;

      .qrTitle {
        padding: 0;
        color: $textColorDark;
        text-align: center;
        font-family: $font;
        font-size: $font-size-base;
        font-style: normal;
        font-weight: $font-weight-bold;
        line-height: 26px;
        text-transform: uppercase;
      }
      .ticketId {
        padding: 0;
        margin: $space-md 0;
        color: $textColorGrey;
        text-align: center;
        font-family: $font;
        font-size: $font-size-small;
        font-style: normal;
        font-weight: $font-weight-normal;
        line-height: $font-size-small; /* 100% */
      }
    }
    .sectionInfo {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding: $space-md $space-lg;
      background-color: $headerBgColor;

      span {
        color: $textColorLight;
        text-align: center;
        font-family: $font;
        font-size: $font-size-small;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        line-height: 26px; /* 260% */
        text-transform: uppercase;
      }
    }

    .detailsContainer {
      margin-top: $space-lg;
      padding: 0 $space-lg $space-xl;
      display: flex;
      flex-direction: column;
      gap: $space-lg;
      .eventInfo {
        .event {
          display: flex;
          width: 100%;
          color: $textColorGrey;
          font-family: $font;
          font-size: $font-size-small;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: 26px;
        }
        .performance {
          display: flex;
          width: 100%;
          color: $modalBgColor;
          font-family: $font;
          font-size: $font-size-small;
          font-style: normal;
          font-weight: $font-weight-semi-bold;
          line-height: 26px;
        }
      }
      .directionsInfo {
        display: flex;
        flex-direction: column;
        gap: $space-md;
        .date {
          display: flex;
          gap: $space-sm;
          width: 100%;
          color: $primaryColor;
          font-size: $font-size-base;
          justify-content: flex-start;
          align-items: center;
          .info {
            font-family: $font;
            font-size: $font-size-small;
            font-style: normal;
            font-weight: $font-weight-normal;
            line-height: normal;
          }
        }
        .location {
          display: flex;
          gap: $space-sm;
          width: 100%;
          color: $headerBgColor;
          font-family: $font;
          font-size: $font-size-base;
          justify-content: flex-start;
          align-items: center;
          .info {
            font-family: $font;
            font-size: $font-size-small;
            font-style: normal;
            font-weight: $font-weight-semi-bold;
            line-height: 17px;
          }
        }
      }
    }
  }
}

@media print {
  .ticketCard {
    page-break-inside: avoid; /* Prevent tickets from breaking across pages */
    margin-bottom: 20px;
  }
}
