@import "../../scss/variables.scss";

.promoterLandingPage {
  .promoterLandingPageContainer {
    margin: 0 0 $space-xxl;
    .bannerContainer {
      .banner {
        width: 100%;
      }
    }
    .contentWrapper {
      padding: 0 $mobilePadding;
      transform: translateY(-70px);
      .promoterDetailsContainer {
        // padding: 0 $mobilePadding;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .promoterProfilePicContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          overflow: hidden;
          width: 185px;
          height: 185px;
          z-index: 10;
          transform: translateY(40px);
          border: $space-xs solid $primaryColor;
          .profilePic {
            width: 185px;
            height: 185px;
          }
        }
        .promoterDetails {
          width: 100%;
          .details {
            width: 100%;
            padding: 44px $space-lg $space-lg;
            background-color: $headerBgColor;
            border-radius: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            color: $textColorLight;
            font-family: $font;
            .promoterName {
              font-size: $font-size-base;
              font-weight: $font-weight-extra-bold;
              line-height: normal;
            }
            .promoterEmail {
              font-size: $font-size-small;
              font-weight: $font-weight-semi-bold;
            }
            .numberOfEvents {
              font-size: $font-size-small;
              font-weight: $font-weight-normal;
              color: $primaryColor;
              padding: $space-sm 0 0;
            }
          }
          .socialMedia {
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: $space-lg 0;
            gap: $space-md;
            .socialMediaItem {
              background-color: $headerBgColor;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: all $transition-fast ease-in-out;
              .socialMediaLink {
                color: $textColorLight;
                font-size: $font-size-large;
                transition: all $transition-fast ease-in-out;
              }
              &:hover {
                // background-color: $primaryColor;
                .socialMediaLink {
                  // color: $textColorDark;
                  color: $primaryColor;
                }
              }
            }
          }
        }
      }
      .promoterDescriptionContainer {
        // padding: 0 $mobilePadding;
        .promoterDescription {
          margin-top: $space-md;
        }
      }
      .eventsContainer {
        margin-top: $space-lg;
        // padding: 0 $mobilePadding;
        .eventsList {
          margin-top: $space-md;
          list-style: none;
        }
      }
    }
  }

  @include smallBreakpoint {
    padding: 0;
    .promoterLandingPageContainer {
      .contentWrapper {
        padding: 0 $tabletPadding;
      }
    }
  }

  @include mediumBreakpoint {
    padding: 0;
    .promoterLandingPageContainer {
      .contentWrapper {
        .promoterDetailsContainer {
          flex-direction: row;
          transform: translateX(-20px);
          justify-content: flex-start;
          .promoterProfilePicContainer {
            // width: 250px;
            // height: 250px;
            min-width: 185px;
            min-height: 185px;
            // border: $space-sm solid $primaryColor;
            transform: translateY(0) translateX(20px);
            .profilePic {
              min-width: 185px;
              min-height: 185px;
              // width: 250px;
              // height: 250px;
            }
          }
          .promoterDetails {
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            gap: $space-md;
            // width: 100%;
            .details {
              // padding: 44px $space-xl 44px 100px;
              width: auto;
              .promoterName {
                // font-size: $font-size-h2;
              }
              .promoterEmail {
                // font-size: $font-size-base;
              }
              .numberOfEvents {
                // font-size: $font-size-base;
              }
            }
            .socialMedia {
              padding: 0;
              flex-direction: column;
            }
          }
        }
      }
    }
  }

  @include largeBreakpoint {
    padding: 0;
    .promoterLandingPageContainer {
      .contentWrapper {
        padding: 0 $desktopPadding;
        transform: translateY(-125px);
        .promoterDetailsContainer {
          flex-direction: row;
          transform: translateX(-80px);
          justify-content: flex-start;
          .promoterProfilePicContainer {
            min-width: 250px;
            min-height: 250px;
            border: $space-sm solid $primaryColor;
            transform: translateY(0) translateX(80px);
            .profilePic {
              min-width: 250px;
              min-height: 250px;
            }
          }
          .promoterDetails {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            gap: $space-md;
            // width: 100%;
            .details {
              padding: 44px $space-xl 44px 100px;
              width: 100%;
              .promoterName {
                font-size: $font-size-h2;
              }
              .promoterEmail {
                font-size: $font-size-base;
              }
              .numberOfEvents {
                font-size: $font-size-base;
              }
            }
            .socialMedia {
              padding: 0;
              flex-direction: row;
            }
          }
        }
        .promoterDescriptionContainer {
          margin-top: $space-lg;
          .promoterDescription {
            margin-top: 0;
          }
        }
      }
    }
  }
  @include xLargeBreakpoint {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .promoterLandingPageContainer {
      // background-color: $bgColor;
      max-width: $maxContentWidth;
      .bannerContainer {
        // padding: 0 $desktopPadding;
      }
      .contentWrapper {
        // padding: 0;
      }
    }
  }
}

.whiteScreen {
  background-color: $bgColor;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
