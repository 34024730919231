@import "../../scss/variables.scss";

.filterChip {
  display: flex;
  width: 130px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #e9f6fc;
  color: $headerBgColor;
  font-family: $font;
  font-size: $font-size-base;
  font-weight: $font-weight-semi-bold;
  text-transform: capitalize;
  .chipBtn {
    background-color: transparent;
    border: none;
    font-size: $font-size-base;
    color: $headerBgColor;
  }
}
