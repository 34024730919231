@import "../../scss/variables.scss";

.similarEventsCard {
  width: 100%;
  max-width: 310px;
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: $space-md;
  background-color: $bgColor;
  border-radius: $border-radius-md;
  padding: $space-ms $space-md;
  box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);

  .imageContainer {
    width: 100%;
    height: 100%;
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: $border-radius-md;
    }
  }

  .infoContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title {
      font-size: $font-size-base;
    }
    .date,
    .venue,
    .price {
      font-size: $font-size-small;
      color: $textColorGrey;
    }
    .date {
      margin-top: $space-ms;
    }
    .venue {
      margin-top: $space-xs;
    }
    .price {
      margin-top: $space-md;
    }
  }

  .btnsContainer {
    display: none;
  }
}
