@import "../../scss/variables.scss";

.eventListCard {
  width: 100%;
  max-width: 530px;
  display: flex;
  flex-direction: column;
  gap: $space-sm;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-bottom: $space-ml;
  border-radius: $border-radius-md;
  padding: $space-md;
  background-color: $bgColor;
  .imgContainer {
    width: 100%;
    height: 48vw;
    overflow: hidden;
    border-radius: $border-radius-md $border-radius-md 0 0;
    .thumbnail {
      width: 100%;
    }
  }
  .infoContainer {
    // padding: 0 $space-md;
    display: grid;
    grid-template-columns: 1fr $space-xxl;
    gap: $space-sm;
    .dataContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: $space-sm;
      .eventName {
        margin-top: $space-md;
        font-family: $font;
        font-weight: $font-weight-extra-bold;
        line-height: 20px;
      }
      .middleLine {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .detail {
          color: $textColorLightGrey;
          font-family: $font;
          font-size: $font-size-small;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: 23px; /* 191.667% */
        }
      }
      .shareCount {
        color: $textColorLightGrey;
        font-size: 10px;
        font-style: normal;
        font-weight: $font-weight-normal;
        margin-top: $space-ml;
      }
      .eventType {
        color: $pink;
        font-family: $font;
        font-size: $font-size-small;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        line-height: 23px; /* 164.286% */
        text-transform: uppercase;
      }
    }
    .btnsContainer {
      width: 100%;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: $space-md;
      .shareBtn {
        // color: $primaryColor;

        background-color: transparent;
        border: none;
        // font-size: $font-size-large;
        padding: 0;
        margin: 0;
      }
      .detailsBtnContainer {
        // display: none;
      }
    }
  }

  @include mediumBreakpoint {
    padding: 0;
    display: grid;
    grid-template-columns:
      minmax(166px, 1fr)
      3fr;
    height: 166px;
    max-width: none;
    overflow: hidden;
    .imgContainer {
      width: 100%;
      height: auto;
      overflow: hidden;
      border-radius: $border-radius-md 0 0 $border-radius-md;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .thumbnail {
        width: 100%;
        width: 100%;
      }
    }
    .infoContainer {
      padding: $space-md $space-lg $space-md $space-md;
      grid-template-columns: 2fr minmax(97px, 1fr);
      gap: $space-ml;
      .dataContainer {
        gap: 0;
        .eventName {
          font-size: $font-size-large;
        }
        .eventType {
          margin-top: $space-md;
          font-size: $font-size-base;
        }
        .middleLine {
          width: 100%;
          margin-top: $space-sm;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: $space-lg;
          .detail {
            font-size: $font-size-base;
          }
        }
      }
      .btnsContainer {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        gap: $space-lg;
        // justify-content: space-between;
        margin-top: 0;
        .shareBtn {
          width: 36px;
          height: 36px;
          img {
            width: 36px;
            height: 36px;
          }
        }
        .detailsBtnContainer {
          width: 100%;
          max-width: 170px;
        }
      }
    }
  }
  @include largeBreakpoint {
    height: 200px;
    grid-template-columns:
      minmax(200px, 1fr)
      3fr;
    padding: 0;
    .infoContainer {
      // padding: $space-md $space-lg $space-md $space-md;
      grid-template-columns: 2fr 1fr;
      // gap: $space-ml;
      .dataContainer {
        .middleLine {
          display: flex;
          gap: 0 $space-lg;
          flex-wrap: wrap;
          // min-width: 250px;
          .detail {
            width: fit-content;
            font-size: $font-size-small;
          }
        }
      }
    }
  }

  @include xLargeBreakpoint {
    height: 183px;
    grid-template-columns:
      minmax(200px, 1fr)
      3fr;
    padding: 0;
    overflow: hidden;
    .infoContainer {
      .dataContainer {
        .middleLine {
          .detail {
            font-size: $font-size-base;
          }
        }
      }
    }
  }
}
