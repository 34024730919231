@import "../../../scss/variables.scss";

.statusDetails {
  .itemContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: $space-lg;
    .item {
      display: grid;
      grid-template-columns: 12px 1fr;
      gap: $space-sm;
      padding: 0 $mobilePadding;
      margin-top: $space-lg;
      .grey,
      .green,
      .orange,
      .red {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .grey {
        background-color: #d9d9d9;
      }
      .green {
        background-color: #9cffd0;
      }
      .orange {
        background-color: #ffeee1;
      }
      .red {
        background-color: #ff9d9d;
      }

      .data {
        display: flex;
        flex-direction: column;
        gap: $space-md;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        .date {
          font-size: $font-size-small;
          line-height: $font-size-small;
          color: #5e5e5e;

          font-family: $font;

          font-weight: $font-weight-semi-bold;
          padding: 0;
          // line-height: 26px; /* 216.667% */
        }
        p {
          font-family: $font;
          font-size: $font-size-small;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: $space-ml; /* 153.846% */
          button {
            background: none;
            border: none;
            color: $primaryColor;
            text-decoration: underline;
            font-family: $font;
            font-size: $font-size-small;
            font-style: normal;
            font-weight: $font-weight-semi-bold;
            line-height: $space-ml; /* 153.846% */
          }
        }
      }
    }

    .divider {
      //   margin-top: $space-md;
      background-color: #f5f5f5;
      height: 1px;
      width: 100%;
    }
  }
}
