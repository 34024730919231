@import "../../scss/custom.scss";

.customDropdown {
  position: relative;
  display: inline-block;
  .btn {
    display: flex;
    padding: 7px 9px 7px 15px;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px;
    border: none;
    background-color: $bgColor;
    padding: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    position: relative;
    span {
      margin: 0;
      padding: 0;
      color: $lightGrey;
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
    &:hover {
      background-color: $bgColor;
    }
    &:active {
      background-color: $bgColor;
    }
  }

  .modal {
    display: none;
    background-color: $languagePickerBgColor;
    width: 100%;
    height: auto;
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    border-radius: 15px;
    z-index: 3000;
    padding: 0;
    &.active {
      display: block;
    }
    .btnsContainer {
      display: grid;
      background-color: $bgColor;
      border-radius: 10px;
      .chosenOpt {
        display: flex;
        padding: 7px 9px 7px 15px;
        justify-content: flex-end;
        align-items: center;
        flex-shrink: 0;
        border-radius: 20px;
        border: none;
        background-color: $bgColor;
        padding: 0;
        margin: 0;
        align-items: center;
        justify-content: center;
        position: relative;
        span {
          margin: 0;
          padding: 0;
          color: $lightGrey;
          font-size: 12px;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
        }
      }

      .changeBtn {
        border-radius: 10px;
        text-align: left;
        width: 100%;
        background-color: transparent;
        color: $primaryColor;
        padding: 10px 5px 10px 15px;
        border: none;
        font-size: 12px;
        color: $lightGrey;
        height: 35px;
        display: flex;
        align-items: center;
        &:hover {
          background-color: $primaryColor;
          border: none;
          color: $textColorLight;
        }
      }
    }
  }
  &.eventSort {
    .btn {
      font-size: $font-size-small;
      padding: $space-sm $space-md;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: $bgColor;
      color: $headerBgColor;
      border: 1px solid $inputFieldGreyBorderColor;
      height: 51px;
      border-radius: $border-radius-md;
      span {
        gap: 11px;
        // width: 100%;
        font-style: normal;
        letter-spacing: 0.2px;
        font-family: $font;
        font-size: $font-size-base;
        font-style: normal;
        font-weight: $font-weight-normal;
        color: $headerBgColor;
        line-height: 24px; /* 200% */
        .icon {
          color: #737373;
        }
      }
    }
    .modal {
      .btnsContainer {
        background-color: $iceWhite;
        border-radius: 5px;
        border: 1px solid $headerBgColor;
        .chosenOpt {
          padding: 6px 8px 6px 18px;
          background-color: $iceWhite;
          justify-content: flex-end;
          align-items: center;
          border-radius: 5px;
          span {
            gap: 11px;
            color: $headerBgColor;
            font-size: $font-size-small;
            font-style: normal;
            font-weight: $font-weight-semi-bold;
            line-height: 28px;
            letter-spacing: 0.2px;
            font-family: $specialFont;
            .icon {
              color: #737373;
            }
          }
        }
        .changeBtn {
          padding: 0;
          color: $headerBgColor;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0.2px;
          font-family: $specialFont;
          padding-right: 8px;
          padding-left: 18px;
          border-radius: 4px;
        }
      }
    }
  }
}
