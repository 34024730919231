@import "../../scss/variables.scss";

.resetPassword {
  background: url("../../assets/banner.jpg") no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 75px 0;
  min-height: calc(100vh - (#{$headerMobileHeight} + #{$mobileFooterHeight}));
  flex: 1;
  .resetPasswordCard {
    background-color: $headerBgColor;
    width: 425px;
    max-width: 100%;
    flex-shrink: 0;
    border-radius: 5px;
    padding: 31px 35px 96px;
    form {
      padding-top: 22px;
      padding-bottom: 27px;
      gap: 23px;
      display: flex;
      flex-direction: column;
    }
    h2 {
      font-size: $font-size-h2;
      font-weight: $font-weight-bold;
      color: $primaryColor;
    }
  }
  @include largeBreakpoint {
    padding-left: 0;
    padding-right: 0;
    min-height: calc(100vh - (#{$headerHeight} + #{$desktopFooterHeight}));
  }
}
