@import "../../scss/variables.scss";

.eventDashboard {
  padding: 17px $mobilePadding 30px;
  background-color: $greyBackground;
  flex: 1;
  min-height: 100%;
  .eventDashboardContainer {
    .topContainer {
      .pathContainer {
        display: flex;
        align-items: center;
        width: 100%;
        .pathLink {
          // margin: 10px 24px;
          text-align: center;
          font-family: $font;
          font-size: $font-size-small;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: normal;
          text-decoration: none;
          color: $ticketInfoBGGrey;
          &:hover {
            border-bottom: $hoverBorder;
          }
          &:first-child {
            margin-left: 0;
          }
        }
        .arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: $font-size-small;
          color: $ticketInfoBGGrey;
          padding: 0 $space-sm;
        }
      }
      .dashboardHeader {
        .titleContainer {
          .title {
            margin-top: $space-xs;
          }
          .locationLabel {
            color: #1c1b1f;
            margin-top: $space-md;
            font-family: $font;
            font-size: $font-size-base;
            font-style: normal;
            font-weight: $font-weight-semi-bold;
            line-height: normal;
            strong {
              font-weight: $font-weight-bold;
            }
          }
        }

        .searchContainer {
          .search {
            display: flex;
            width: 100%;
            height: 45px;
            margin-top: $space-md;
            .searchIcon {
              height: 100%;
              border-top: 1px solid $inputFieldGreyBorderColor;
              border-right: none;
              border-bottom: 1px solid $inputFieldGreyBorderColor;
              border-left: 1px solid $inputFieldGreyBorderColor;
              color: #d9d9d9;
              background-color: $bgColor;
              display: flex;
              padding: $space-sm;
              align-items: center;
              flex-shrink: 0;
              font-size: 1.25rem;
            }
            input {
              height: 100%;
              width: 100%;
              background-color: $bgColor;
              padding: $space-sm $space-ml;
              border-top: 1px solid $inputFieldGreyBorderColor;
              border-right: none;
              border-bottom: 1px solid $inputFieldGreyBorderColor;
              border-left: none;
              border-radius: $border-radius-md 0 0 $border-radius-md;
              color: $textColorSemiDark;
              font-family: $specialFont;
              font-size: $font-size-base;
              font-style: normal;
              font-weight: $font-weight-normal;
              line-height: $font-size-h1;
              letter-spacing: 0.2px;
              &:focus {
                outline: none;
              }
            }
            button {
              height: 100%;
              width: $space-xl;
              border: 1px solid $primaryColor;
              border-radius: 0 $border-radius-md $border-radius-md 0;
              color: $textColorLight;
              background-color: $primaryColor;
              display: flex;
              padding: $space-sm;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              font-size: $space-ml;
              transition: $transition-fast ease-in-out;
              // &.focused {
              //   background-color: $primaryColor;
              //   color: $textColorLight;
              // }
            }
          }
          .changeLocationBtn {
            margin-top: $space-md;
            background-color: transparent;
            border: none;
            color: $primaryColor;
            font-size: $font-size-small;
            font-weight: $font-weight-normal;
            text-decoration: underline;
          }
        }
      }
    }
    .dataSection {
      .filtersContainer {
        width: 350px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        animation: slideIn 0.2s ease-out forwards;
        display: none;
        &.showFilter {
          display: block;
        }
      }
      .dataContainer {
        margin-top: $space-lg;
        .dataHeaderGroup {
          .ticketsCount {
            font-family: $font;
            font-size: $font-size-base;
            font-style: normal;
            font-weight: $font-weight-normal;
            strong {
              font-weight: $font-weight-bold;
            }
          }
          .sortContainer {
            margin-top: $space-ml;
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            justify-content: space-between;
            gap: 15px;
            width: 100%;
            .btnContainer {
              width: 100%;
            }
          }
        }
        .eventList {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          gap: 24px;
          margin-top: 22px;
          position: relative;
          list-style: none;
        }
      }
    }
  }

  @include smallBreakpoint {
    .eventDashboardContainer {
      .topContainer {
        max-width: 530px;
      }
      .dataSection {
        .dataContainer {
          .dataHeaderGroup {
            max-width: 530px;
          }
          .eventList {
            align-items: flex-start;
          }
        }
      }
    }
  }

  @include mediumBreakpoint {
    .eventDashboardContainer {
      .topContainer {
        max-width: none;
      }
      .dataSection {
        .dataContainer {
          .dataHeaderGroup {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            max-width: none;
            .sortContainer {
              margin-top: 0;
              max-width: 340px;
            }
          }
        }
      }
    }
  }
  @include largeBreakpoint {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    .eventDashboardContainer {
      width: 100%;
      max-width: $maxContentWidth;
      .topContainer {
        gap: 40px;
        .searchContainer {
          order: 2;
        }
        .pathContainer {
          display: flex;
          align-items: center;
          a {
            font-size: 16px;

            &:hover {
              border-bottom: $hoverBorder;
            }
            &:first-child {
              margin-left: 0;
            }
          }
          .arrow {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .dashboardHeader {
          display: grid;
          grid-template-columns: 300px 1fr;
          gap: 40px;
          .titleContainer {
            .title {
              font-size: $font-size-h1;
              margin-top: $space-lg;
            }
          }
          .searchContainer {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            .search {
              max-width: 777px;
            }
            .changeLocationBtn {
              display: none;
            }
          }
        }
      }
      .dataSection {
        margin-top: $space-lg;
        display: grid;
        gap: 40px;
        grid-template-columns: 300px 1fr;
        .filtersContainer {
          width: 300px;
          position: static;
          display: block;
          z-index: 0;
          animation: none;
        }
        .dataContainer {
          margin-top: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          .dataHeaderGroup {
            width: 100%;

            .sortContainer {
              width: 154px;
              max-width: 941px;
              justify-content: flex-end;
              gap: 15px;
              display: flex;
              .btnContainer {
                display: none;
              }
            }
          }
          .eventList {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            gap: 24px;
            margin-top: 22px;
            position: relative;
          }
        }
      }
    }
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-350px);
  }
  100% {
    transform: translateX(0);
  }
}

.filterBtnContent {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: $space-sm;
  font-family: $font;
  font-size: $font-size-base;
  font-style: normal;
  font-weight: $font-weight-normal;
  color: $headerBgColor;
  line-height: 24px; /* 200% */
}
