@import "../../../scss/variables.scss";

.orderCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 550px;
  border: none;
  border-radius: $border-radius-md;
  color: $textColorLight;
  font-family: $font;
  padding: $space-ml 0 0;
  background-color: $extraDarkBlue;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  .orderCardHeader {
    padding: 0 $space-md;
    .orderIdContainer {
      display: flex;
      gap: $space-md;
      align-items: flex-end;
      width: 100%;
      justify-content: space-between;
      .orderInfo {
        display: flex;
        gap: $space-md;
        align-items: flex-end;
        .id {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .label {
            font-size: $font-size-small;
            color: $labelGrey;
            font-family: $font;
            font-style: normal;
            font-weight: $font-weight-normal;
            text-transform: uppercase;
          }
          .orderId {
            color: $textColorLight;
            font-family: $font;
            font-size: $font-size-base;
            font-style: normal;
            font-weight: $font-weight-bold;
            line-height: 26px;
          }
        }
        .status {
          display: flex;
          gap: $space-xs;
          align-items: center;
          .dot {
            height: $space-sm;
            width: $space-sm;
            border-radius: 50%;
            background-color: $successColor;
            line-height: 26px;
          }
          .statusText {
            color: $successColor;
            font-size: $font-size-small;
            font-weight: 500;
            text-transform: uppercase;
            line-height: 26px;
            font-family: $font;
          }
        }
        .cancelOrderContainer {
          display: flex;
          gap: $space-xs;
          align-items: flex-end;
          justify-content: flex-end;
          height: 100%;
        }
      }
    }
    .orderDetails {
      margin-top: $space-ml;
      display: flex;
      gap: $space-md;
      align-items: center;
      justify-content: flex-start;

      .orderDate,
      .orderTotal {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .label {
          font-size: $font-size-small;
          color: $labelGrey;
          font-family: $font;
          font-style: normal;
          font-weight: $font-weight-normal;
          text-transform: uppercase;
        }
      }
      .info {
        color: $textColorLight;
        font-family: $font;
        font-size: $font-size-small;
        font-style: normal;
        font-weight: $font-weight-normal;
        // line-height: 26px;
      }
    }
  }

  .event {
    margin-top: $space-md;
    padding: $space-xs $space-md;
    font-size: $font-size-small;
    color: $greyTextColor;

    align-items: flex-start;
    .eventContainer {
      display: flex;
      flex-direction: column;
      .label {
        font-size: $font-size-small;
        color: $labelGrey;
        font-family: $font;
        font-style: normal;
        font-weight: $font-weight-normal;
        text-transform: uppercase;
      }
      .eventName {
        font-size: $font-size-base;
        color: $primaryColor;
        font-family: $font;
        font-style: normal;
        font-weight: 700;
        // line-height: 26px;
        text-transform: uppercase;
        font-size: $font-size-small;
      }
    }
  }

  .btnContainer {
    padding: 0 $space-md;
    display: flex;
    margin-top: $space-lg;
    width: 100%;
  }

  .showMoreBtnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: $space-xs 0 $space-ml;

    .showMore {
      color: $textColorLight;
      background: transparent;
      border: none;
      margin-top: $space-ml;
      cursor: pointer;

      .showBtnContent {
        display: flex;
        gap: $space-sm;
        align-items: center;
        justify-content: center;
        font-size: $font-size-small;
        .text {
          font-size: $font-size-small;
          font-weight: $font-weight-bold;
          text-transform: uppercase;
          color: $textColorLight;
        }
      }

      &:hover {
        color: $primaryColor;
        transition: color $transition-fast ease-in-out;
        .showBtnContent {
          .text {
            color: $primaryColor;
            transition: color $transition-fast ease-in-out;
          }
        }
      }
    }
  }
  .showMoreContainer {
    display: flex;
    flex-direction: column;
    gap: $space-sm;
    width: 100%;
    // padding: $space-ml 0 0;
    .ticketsInfoContainer {
      list-style: none;
      padding: $space-sm 0 0;
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: $space-sm;
    }
    .viewAllReadyBtnContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: $space-ml;
    }
    .contactContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #05233f;
      border-radius: 0px 0px 10px 10px;
      padding: $space-ml;
      .contactText {
        color: $textColorLight;
        font-size: $font-size-small;
        font-family: $font;
        font-style: normal;
        font-weight: font-weight-normal;
        line-height: 17px; /* 141.667% */
        text-align: center;
        .contactLink {
          color: $textColorLight;
          text-decoration: underline;
          cursor: pointer;
          font-weight: $font-weight-bold;
          background: transparent;
          border: none;
        }
      }
    }
  }

  &.canceled {
    .orderCardHeader {
      .orderInfo {
        .status {
          .dot {
            background-color: $lightDanger;
          }
          .statusText {
            color: $lightDanger;
          }
        }
      }
    }
  }
  &.refunded {
    .orderCardHeader {
      .orderInfo {
        .status {
          .dot {
            background-color: $lightBlue;
          }
          .statusText {
            color: $lightBlue;
          }
        }
      }
    }
  }
}
