@import "../../../scss/variables.scss";
.claims {
  margin: $space-lg 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: $space-md;
  .claimsContainer {
    .claimsHeader {
      padding: 0 $mobilePadding $space-lg;

      h2 {
      }
    }
    .claimsList {
      display: flex;
      flex-direction: column;
      gap: $space-md;
      list-style: none;
    }
  }
}
