@import "../../scss/variables.scss";

.viewTickets {
  // padding: 0 $mobilePadding;
  // margin: $space-lg 0;
  .viewTicketsContainer {
    .viewTicketsHeader {
      padding: $space-xl $mobilePadding;
      .returnBtn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: $font-size-h1;
        float: right;
      }
      .viewTicketsTitle {
        margin-top: $space-lg;
        color: #000;
        font-family: $font;
        font-size: $font-size-h2;
        font-style: normal;
        font-weight: $font-weight-bold;
        line-height: normal;
      }
      .printBtn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: $primaryColor;
        display: flex;
        align-items: center;
        gap: $space-xs;
        font-family: $font;
        font-size: $font-size-base;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        line-height: normal;
        margin-top: $space-md;
        span {
          font-size: $font-size-small;
          border-bottom: 1px solid $primaryColor;
        }
      }
    }
    .cardsContainer {
      padding: $space-xl $mobilePadding;
      // background-color: $greyBackground;
      display: flex;
      flex-direction: column;
      gap: $space-lg;
      width: 100%;
      align-items: center;
      justify-content: center;
      list-style-type: none;
    }
  }
}

.whiteBackground {
  background-color: $iceWhite;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media print {
  body * {
    visibility: hidden; /* Hide everything except the print section */
    -webkit-print-color-adjust: exact !important; /* Ensures colors print correctly */
    print-color-adjust: exact !important;
  }
  .cardsContainer,
  .cardsContainer * {
    display: block;
    visibility: visible;
  }
  .cardsContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  // .cardsContainer li {
  //   // page-break-inside: avoid; /* Prevent tickets from breaking across pages */
  //   // margin-bottom: 20px; /* Adds space between printed tickets */
  // }
}
