@import "../../scss/variables.scss";

.login {
  background: url("../../assets/banner.jpg") no-repeat center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 75px 0;
  min-height: calc(100vh - (#{$headerMobileHeight} + #{$mobileFooterHeight}));
  .loginCard {
    background-color: $headerBgColor;
    width: 425px;
    max-width: 100%;
    flex-shrink: 0;
    border-radius: 5px;
    padding: 31px 35px 96px;
    form {
      padding-top: 22px;
      padding-bottom: 27px;
      gap: 23px;
      display: flex;
      flex-direction: column;
    }
    .legal {
      color: $textColorLight;
      font-family: $interFont;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-bottom: 23px;
    }
  }
  @include largeBreakpoint {
    padding-left: 0;
    padding-right: 0;
    min-height: calc(100vh - (#{$headerHeight} + #{$desktopFooterHeight}));
  }
}
