@import "../../../scss/variables.scss";

.claimCard {
  .details {
    background-color: $bgColor;

    // .detailsHeader {
    //   padding: $space-md 0;
    //   background-color: $greyBackground;
    //   display: flex;
    //   flex-direction: column;
    //   .eventLabel {
    //     color: $textColorDark;
    //     font-family: $font;
    //     font-size: $font-size-small;
    //     font-style: normal;
    //     font-weight: $font-weight-normal;
    //     line-height: 26px;
    //   }
    //   .eventName {
    //     color: $textColorDark;
    //     font-family: $font;
    //     font-size: $font-size-base;
    //     font-style: normal;
    //     font-weight: $font-weight-bold;
    //     line-height: 26px;
    //   }
    // }

    .detailsTicket {
      padding: $space-md $mobilePadding;
      display: flex;
      flex-direction: column;
      width: 100%;

      .detailsTicketLine {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: $space-md;

        .ticketId {
          color: $textColorDark;
          font-family: $font;
          font-size: $font-size-base;
          font-style: normal;
          font-weight: $font-weight-semi-bold;
          // line-height: 13px;s
        }
        .refundStatusTag {
          font-family: $font;
          font-size: $font-size-small;
          font-style: normal;
          font-weight: $font-weight-semi-bold;
          // line-height: 13px;
          padding: $space-xs $space-md;
          border-radius: $border-radius-xl;
          &.pending {
            color: #6c3003;
            background: #ffeee1;
          }
          &.rejected {
            color: #6b0202;
            background: #ffe5e5;
          }
          &.approved {
            color: #004a16;
            background: $lightGreen;
          }
        }
      }
      //   .orderContainer {
      //     margin-top: $space-md;
      //     display: flex;
      //     flex-direction: column;
      //     .orderLabel {
      //       color: #5e5e5e;
      //       font-family: $font;
      //       font-size: 9px;
      //       font-style: normal;
      //       font-weight: $font-weight-normal;
      //       text-transform: uppercase;
      //       // line-height: 26px; /* 288.889% */
      //     }
      //     .orderId {
      //       color: $textColorDark;
      //       font-family: $font;
      //       font-size: font-size-small;
      //       font-style: normal;
      //       font-weight: $font-weight-semi-bold;
      //       // line-height: 26px; /* 216.667% */
      //     }
      //   }
    }
    .detailsPerformance {
      padding: $space-md $mobilePadding;
      border-top: 1px solid $greyBackground;
      border-bottom: 1px solid $greyBackground;
      display: flex;
      justify-content: space-between;
      //   display: grid;
      //   grid-template-columns: repeat(3, 1fr);
      .detailColumn {
        display: flex;
        flex-direction: column;
        .label {
          text-transform: uppercase;
          color: #5e5e5e;
          font-family: $font;
          font-size: $font-size-small;
          font-style: normal;
          font-weight: $font-weight-normal;
          // line-height: 26px; /* 236.364% */
        }
        .data {
          text-transform: uppercase;
          color: #000;
          font-family: $font;
          font-size: $font-size-small;
          font-style: normal;
          font-weight: $font-weight-semi-bold;
          // line-height: 26px; /* 216.667% */
        }
      }
    }
  }
  .detailsBtnContainer {
    padding: $space-md $mobilePadding $space-ml;
  }
}
// .claimCardSeparator {
//   height: $space-sm;
//   background-color: $greyBackground;
// }
