@import "../../scss/variables.scss";

.checkboxGroup {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
  span {
    color: $textColorSemiDark;
    text-transform: capitalize;
  }
}
.eventsFilter {
  width: 100%;
  min-height: 100vh;
  // padding: 42px 38px;
  background-color: $bgColor;
  height: 100vh;
  overflow-y: scroll;
  .eventsFilterHeader {
    padding: $space-lg $space-ml;
    .closeBtn {
      border: none;
      background-color: transparent;
      font-size: $space-ml;
      float: right;
    }
    .eventsFilterTitle {
      margin-top: $space-md;
    }
  }
  section {
    padding: $space-ml;
    .sectionHeader {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      h3 {
        color: #191c1f;
        font-family: $font;
        font-size: $font-size-base;
        font-style: normal;
        font-weight: $font-weight-semi-bold;
        line-height: $font-size-large;
        text-transform: uppercase;
      }
      .showToggler {
        background-color: transparent;
        border: none;
      }
    }
    .filterOpts {
      margin-top: $space-md;
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
      &.types {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;
      }
      .locationOpts {
        display: flex;
        flex-direction: column;
        gap: $space-md;
        .sliderContainer {
          .distanceMessage {
            font-size: $font-size-small;
            margin-bottom: $space-md;
            span {
              color: $primaryColor;
              font-weight: $font-weight-semi-bold;
            }
          }
          .sliderValues {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            span {
              font-size: $font-size-small;
              font-family: $font;
              line-height: 20px;
            }
          }
        }
      }
      &.hidden {
        display: none;
      }
    }
  }
  .divider {
    height: 1px;
    width: 100%;
    background-color: #d7dce2;
  }

  @include largeBreakpoint {
    padding: 0;
    background-color: transparent;
    height: 100%;
    min-height: 100%;
    border: #d7dce2 1px solid;
    .eventsFilterHeader {
      display: none;
    }
    section {
      .sectionHeader {
        .showToggler {
          display: none;
        }
      }
      .filterOpts {
        &.hidden {
          display: flex;
        }
      }
    }
  }
}
