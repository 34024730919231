@import "../../scss/variables.scss";
.customCalendar {
  background-color: transparent !important;
  border: none !important;
  font-family: $font !important;
  padding: 0 $space-ml $space-lg;
}

.customCalendar :global(.react-calendar__navigation) {
  // width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}
.customCalendar :global(.react-calendar__navigation__label) {
  font-weight: bold;
  color: $headerBgColor;
  background-color: transparent !important;
  font-size: $font-size-base;
  font-family: $font;
  font-weight: $font-weight-normal;
  order: -1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5.25px;
}

.customCalendar
  :global(.react-calendar__navigation .react-calendar__navigation__prev-button),
.customCalendar
  :global(
    .react-calendar__navigation .react-calendar__navigation__next-button
  ) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 20px;
  font-weight: $font-weight-normal;
  &:hover {
    background-color: transparent !important;
  }
}
.customCalendar :global(.react-calendar__navigation button) {
  min-width: 20px !important;
  width: 20px;
  height: 20px;
}

.customCalendar :global(.react-calendar__month-view__days) {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr) !important;
}

.customCalendar :global(.react-calendar__tile) {
  width: $space-lg;
  height: $space-lg;
  padding: $space-sm;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-small;
  font-family: $font;
}

.customCalendar :global(.react-calendar__month-view__weekdays) {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr) !important;
  font-size: $font-size-small;
  font-family: $font;
}

.customCalendar :global(.react-calendar__month-view__weekdays__weekday) {
  font-weight: $font-weight-normal;

  color: $headerBgColor;
  abbr {
    text-decoration: none;
  }
  &:hover {
    background-color: transparent;
  }
}

.customCalendar :global(.react-calendar__month-view__days__day--weekend),
.customCalendar :global(.react-calendar__month-view__days__day) {
  color: $headerBgColor;
  &:hover {
    background-color: $lightBlue !important;
    border-radius: $border-radius-md;
  }
}
.customCalendar
  :global(.react-calendar__month-view__days__day--neighboringMonth) {
  color: $lightBlue !important;
  &:hover {
    background-color: transparent;
  }
}
.customCalendar :global(.react-calendar__tile--now) {
  background-color: transparent;
  abbr {
    font-weight: $font-weight-semi-bold;
  }
}
.customCalendar :global(.react-calendar__tile--active) {
  background-color: #e5f7ff;
  abbr {
    color: $headerBgColor;
  }
}

.customCalendar :global(.react-calendar__tile--rangeBothEnds),
.customCalendar :global(.react-calendar__tile--rangeStart),
.customCalendar :global(.react-calendar__tile--rangeEnd) {
  background-color: $primaryColor;
  border-radius: $border-radius-md;
  abbr {
    color: $textColorLight;
  }
}

// }
