@import "../../scss/variables.scss";

.rangeSlider {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.slider {
  width: 100%;
  background-color: #e9f6fc;
  height: 5px;
}

.rangeSlider :global(.range-slider__thumb) {
  background-color: $headerBgColor !important;
  width: 20px;
  height: 20px;
}

.rangeSlider :global(.range-slider__range) {
  background-color: $primaryColor !important; // Ensure it applies
  border-radius: 6px;
}


.rangeSlider :global(.range-slider__thumb[data-lower]) {
  width: 0;
}
