@import "../../../scss/variables.scss";

.loginInfo {
  margin-top: 26px;
  padding-left: $mobilePadding;
  padding-right: $mobilePadding;
  .loginInfoContainer {
    h2 {
      color: $textColorSemiDark;
      font-family: $font;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .desc {
      font-family: $interFont;
      font-size: 11px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      margin-bottom: 25px;
    }
    .inputContainer {
      max-width: 606px;
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
    .bottomContainer {
      margin-top: 46px;
      margin-bottom: 46px;
      .btnsContainer {
        display: flex;
        flex-direction: row;
        gap: 16px;
        max-width: 311px;
        width: 100%;
      }
      .successMsg {
        color: $primaryColor;
        font-family: $interFont;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin-top: 20px;
        display: none;

        img {
          width: 23.258px;
          height: 18.047px;
        }
        &.success {
          display: flex;
        }
      }
    }
  }

  @include smallBreakpoint {
  }

  @include largeBreakpoint {
    // padding: 0;
    .loginInfoContainer {
      h2 {
        font-size: 40px;
      }
      .desc {
        font-size: 16px;
        margin-bottom: 64px;
      }
      .inputContainer {
        gap: 25px;
      }
      .bottomContainer {
        .btnsContainer {
          margin-bottom: 0;
        }
      }
    }
    .divider {
      margin-top: 94px;
      margin-bottom: 94px;
      width: 100%;
      height: 1px;
      background-color: #707070;
    }
  }
}
