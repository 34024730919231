@import "../../scss/variables.scss";

.eventPreview {
  .eventContainer {
    margin-bottom: $space-xl;
    .performances {
      margin-top: $space-xl;
      padding: 0 $mobilePadding;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .performancesContainer {
        .performancesCards {
          h3 {
            font-size: $font-size-large;
            width: 100%;
          }
          .performancesCardsContainer {
            width: 100%;
            margin-top: $space-lg;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: $space-md;
            list-style: none;
          }
        }
      }
    }
    .similarEvents {
      margin-top: $space-xl;
      padding: 0 $mobilePadding;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .similarEventsContainer {
        width: 100%;
        .similarEventsCards {
          width: 100%;
          h3 {
            width: 100%;
            font-size: $font-size-large;
          }
          .similarEventsCardsContainer {
            width: 100%;
            margin-top: $space-lg;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: $space-md;
            list-style: none;
          }
        }
      }
    }

    .sponsors {
      margin-top: $space-lg;
      height: 185px;
      width: 100%;
      background: url("../../assets/sponsor-top.webp"),
        url("../../assets/sponsor-bottom.webp");
      background-repeat: no-repeat, no-repeat;
      background-size: 122px auto, 103px auto;
      background-position: top 0px right 10px, bottom 0 left 0;
      background-color: $headerBgColor;
    }
  }
  @include smallBreakpoint {
    padding-left: 0;
    padding-right: 0;
    .eventContainer {
      .performances {
        padding-left: $tabletPadding;
        padding-right: $tabletPadding;
      }
    }
  }

  @include mediumBreakpoint {
    .eventContainer {
      .performances {
        .performancesContainer {
          .performancesCards {
            h3 {
              font-size: $font-size-large;
            }
            .performancesCardsContainer {
              gap: $space-lg;
            }
          }
        }
      }
      .sponsors {
        height: 240px;
        background-size: 240px auto, 205px auto;
        .sponsorsContainer {
          h3 {
            font-size: $font-size-large;
          }
        }
      }
    }
  }
  @include largeBreakpoint {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    .eventContainer {
      width: 100%;
      margin-bottom: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .social,
      .performances {
        padding-left: $desktopPadding;
        padding-right: $desktopPadding;
      }
      .performances {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: $space-xxl;
        .performancesContainer {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          width: 100%;
          max-width: $maxContentWidth;
          .performancesCards {
            width: 100%;
            h3 {
              width: 100%;
              font-size: $font-size-h1;
              text-align: left;
              font-weight: $font-weight-extra-bold;
            }
            .performancesCardsContainer {
              width: 100%;
              gap: $space-xl;
            }
          }
        }
      }
      .sponsors {
        height: 240px;
        padding-left: $desktopPadding;
        padding-right: $desktopPadding;
        background-size: 407px auto, 347px auto;
        margin-top: 170px;
      }
    }
  }
}
