@import "../../../scss/variables.scss";

.closeAccount {
  padding-left: $mobilePadding;
  padding-right: $mobilePadding;
  h2 {
    color: $textColorSemiDark;
    font-family: $font;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 24px;
  }
  .desc {
    color: $textColorDark;
    font-family: $font;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
  form {
    max-width: 320px;
    margin-top: 32px;
    margin-bottom: 32px;
    .radioGroup {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      gap: 20px;
      margin-top: 22px;
    }
    .msg {
      color: $textColorDark;
      font-family: $interFont;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 16px;
      margin-bottom: 14px;
    }
    .formGroup {
      .btnsContainer {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 22px;
      }
    }
  }
  .divider {
    display: none;
  }

  @include smallBreakpoint {
  }
  @include largeBreakpoint {
    // padding: 0;
    h2 {
      font-size: 40px;
    }
    .desc {
      color: $textColorDark;
      font-family: $interFont;
      font-size: 16px;
      font-weight: 400;
    }
    form {
      max-width: 575px;
      margin-top: 33px;

      textarea {
        width: 552px;
        height: 221px;
        resize: none;
        border: 1px solid #797979;
        border-radius: 5px;
        padding: 17px 16px;
        font-family: $font;
      }
      .msg {
        color: $textColorDark;
        font-family: $interFont;
        font-size: 16px;
        font-weight: 400;
        margin-top: 41px;
        margin-bottom: 21px;
      }
      .formGroup {
        width: 361px;
        .btnsContainer {
          width: 100%;
          margin-top: 69px;
          //   display: grid;
          //   grid-template-columns: 207px 132px;
          //   gap: 22px;
        }
      }
    }
    .divider {
      width: 100%;
      height: 1px;
      background-color: #707070;
      margin-top: 84px;
      margin-bottom: 84px;
      display: block;
    }
  }
}
